export default {
  async rowParteTrabajoMatsist (Vue, idparteTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    const parteTrabajoMatsist = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist)
        )
      )
      .exec()
    return parteTrabajoMatsist[0]
  },
}
